const CardPrice = ({ id, icon, name, users,type , price,  price_promotion, description, access = [], action }) => {
    return (
        <div
            className="price-box aparecer"
        >
            <div className="price-icon">
                <img width="60" src={icon} alt="icon" />
            </div>
            <h4 className="title">{name}</h4>
            <strong style={{color: "#56576e", fontWeight: 500}}>{users}</strong>
            <h3 className="price-rate">{price}</h3>
            <p className="text_annually">{description}</p>
            <hr />
            <ul>
                {access.map((item, index) => <li key={index} className="text-start">{item}</li>)}
            </ul>
            <button onClick={() => action(id)} className="bttn-default">Select Plan</button>
        </div>
    )
}

export default CardPrice