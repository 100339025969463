import { plansMonthly, plansYearly } from "../../constants/plans";
import { convertStringToMoney, formatPrice} from "../utils";
import InputCode from "../InputCode";

const getPriceByForm = (form, discount, period = "Yearly") => {
    const { id_plan, promotionalCode } = form;
    const list = period === "Monthly" ? plansMonthly : plansYearly;
    const price = promotionalCode
        ? list.find((x) => x.id === id_plan).price
        : period === "Monthly"
            ? list.find((x) => x.id === id_plan).price
            : list.find((x) => x.id === id_plan).price;

    return {
        price: convertStringToMoney(price),
        price_w_discount: convertStringToMoney(price) - (promotionalCode ? discount : 0),
        format: `$${convertStringToMoney(price) - (promotionalCode ? discount : 0)
            }`,
    };
};

const PaymentStep = ({ form, setForm, step, setStep }) => {

    const handleSubmit = () => {
        console.log(form);
        setStep(step + 1);
    };

    return (
        <div className="container-payment">
            <div className="container-payment-header">
                <p className="payment-subtitle">
                    Select Period ({plansMonthly.find((x) => x.id === form.id_plan).name})
                </p>
            </div>
            <div className="payment-select-period">
                <div
                    onClick={() => setForm({ ...form, type: "1" })}
                    className={`payment-period ${form.type === "1" && "active"}`}
                >
                    <p className="payment-period-title">
                        1st Year&nbsp;
                        {form.promotionalCode && (
                            <span className="span">
                                -$250 off
                            </span>
                        )}
                    </p>
                    <p className="payment-period-subtitle">
                        {form.promotionalCode && (
                            <span className="tachado">${getPriceByForm(form, 0).price}</span>
                        )}
                        &nbsp;
                        {getPriceByForm(form, 250).format}
                    </p>
                    <p className="text-spanUser">Per user / year</p>
                    {form.promotionalCode && <p className="text-spanSaved">Save up to $250 per user</p>}
                </div>
                <div
                    onClick={() => setForm({ ...form, type: "2" })}
                    className={`payment-period ${form.type === "2" && "active"}`}
                >
                    <p className="payment-period-title">
                        2nd Year&nbsp;
                        {form.promotionalCode && (
                            <span className="span">
                                -$250 off
                            </span>
                        )}
                    </p>
                    <p className="payment-period-subtitle">
                        {form.promotionalCode && (
                            <span className="tachado">${getPriceByForm(form, 0).price}</span>
                        )}
                        &nbsp;
                        {getPriceByForm(form, 250).format}
                    </p>
                    <p className="text-spanUser">Per user / year</p>
                    {form.promotionalCode && <p className="text-spanSaved">Save up to $500 per user</p>}
                </div>
                <div
                    onClick={() => setForm({ ...form, type: "3" })}
                    className={`payment-period ${form.type === "3" && "active"}`}
                >
                    <p className="payment-period-title">
                        3rd Year&nbsp;
                        {form.promotionalCode && (
                            <span className="span">
                                -$500 off
                            </span>
                        )}
                    </p>
                    <p className="payment-period-subtitle">
                        {form.promotionalCode && (
                            <span className="tachado">${getPriceByForm(form, 0).price}</span>
                        )}
                        &nbsp;
                        {getPriceByForm(form, 500).format}
                    </p>
                    <p className="text-spanUser">Per user / year</p>
                    {form.promotionalCode && <p className="text-spanSaved">Save up to $1000 per user</p>}
                </div>
            </div>
            <hr></hr>
            <div className="container-payment-header">
                <p className="payment-subtitle">Promotion Code</p>
                <InputCode
                    action={(val) => setForm({ ...form, promotionalCode: val })}
                />
            </div>
            <hr></hr>
            <p className="payment-subtitle">Payment installments</p>
            <div className="payment-form">
                <div className="switch_price_container">
                    <h5 onClick={() => setForm({ ...form, period: "Monthly" })}>
                        <input checked={form.period === "Monthly" ? true : false} type="radio"/>&nbsp;
                        {(form.promotionalCode && form.type === "3") && (<>
                            1st year<strong>/ {getPriceByForm(form, 21, "Monthly").format}</strong> per month per user
                            <br/>
                            2nd year<strong>/ {getPriceByForm(form, 21, "Monthly").format}</strong> per month per user
                            <br/>
                            3rd year<strong>/ {getPriceByForm(form, 42, "Monthly").format}</strong> per month per user
                        </>)}                        
                        {(!form.promotionalCode && form.type === "3") && (<>
                            1st year <strong>{getPriceByForm(form, form.type === "3" ? 28 : 21, "Monthly").format}</strong> per month per user
                            <br/>
                            2nd year <strong>{getPriceByForm(form, form.type === "3" ? 28 : 21, "Monthly").format}</strong> per month per user
                            <br/>
                            3rd year <strong>{getPriceByForm(form, form.type === "3" ? 28 : 21, "Monthly").format}</strong> per month per user
                        </>)}                        
                        {(form.type === "2") && (<>
                            1st year <strong>{getPriceByForm(form, form.type === "3" ? 28 : 21, "Monthly").format}</strong> per month per user
                            <br/>
                            2nd year <strong>{getPriceByForm(form, form.type === "3" ? 28 : 21, "Monthly").format}</strong> per month per user
                        </>)}                        
                        {(form.type === "1") && (<>
                            1st year <strong>{getPriceByForm(form, form.type === "3" ? 28 : 21, "Monthly").format}</strong> per month per user
                        </>)}                        
                        {/* {
                            (form.promotionalCode && form.type === "3")
                            ? <>
                                 Pay1 for {12*2} months <strong>{getPriceByForm(form, 21, "Monthly").format}/ mo</strong> per user
                                <br/>
                                Pay for last 12 months <strong>{getPriceByForm(form, 42, "Monthly").format}/ mo</strong> per user
                            </>
                            : <>
                                Pay2 for {12*parseInt(form.type || "1")} months <strong>{getPriceByForm(form, form.type === "3" ? 28 : 21, "Monthly").format}/ mo</strong> per user
                            </>
                        } */}
                       
                    </h5>
                    <h5 onClick={() => setForm({ ...form, period: "Yearly" })} style={{textAlign:"end"}}>
                        <input checked={form.period === "Yearly" ? true : false} type="radio"/>&nbsp;
                        {(form.promotionalCode && form.type === "3") && (<>
                            1st year<strong>/ {getPriceByForm(form, 250).format}</strong> per year per user
                            <br />
                            2nd year<strong>/ {getPriceByForm(form, 250).format}</strong> per year per user
                            <br />
                            3rd year<strong>/ {getPriceByForm(form, 500).format}</strong> per year per user
                        </>)}                        
                        {(!form.promotionalCode && form.type === "3") && (<>
                            1st year<strong>/ {getPriceByForm(form, form.type === "3" ? 333 : 250).format}</strong> per year per user
                            <br />
                            2nd year<strong>/ {getPriceByForm(form, form.type === "3" ? 333 : 250).format}</strong> per year per user
                            <br />
                            3rd year<strong>/ {getPriceByForm(form, form.type === "3" ? 333 : 250).format}</strong> per year per user
                        </>)}                        
                        {(form.type === "2") && (<>
                            1st year<strong>/ {getPriceByForm(form, form.type === "3" ? 333 : 250).format}</strong> per year per user
                            <br />
                            2nd year<strong>/ {getPriceByForm(form, form.type === "3" ? 333 : 250).format}</strong> per year per user
                        </>)}                        
                        {(form.type === "1") && (<>
                            1st year<strong>/ {getPriceByForm(form, form.type === "3" ? 333 : 250).format}</strong> per year per user                    
                        </>)}    
                        {/* {
                            (form.promotionalCode && form.type === "3")
                            ? <>
                                Pay for 2 years <strong>{getPriceByForm(form, 250).format}/ year</strong> per user
                                <br/>
                                Pay for last year <strong>{getPriceByForm(form, 500).format}/ year</strong> per user
                            </>
                            : <>
                                Pay for {form.type} years <strong>{getPriceByForm(form, form.type === "3" ? 333 : 250).format}/ year</strong> per user
                            </>
                        } */}
                        
                    </h5>
                </div>
            </div>
            <div className="payment-footer">
                <button onClick={() => setStep(step - 1)} className="payment-button-2">
                    Back
                </button>
                <button onClick={handleSubmit} className="payment-button">
                    Next
                </button>
            </div>
        </div>
    );
};

export default PaymentStep;
