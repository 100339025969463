import { useState } from "react";

const InputCode = ({ action }) => {
    const [error, setError] = useState(false);

    const handlePromotionalCode = (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        const code = formData.get('code');
        if (code === "FRSA23") {
            setError(false)
            action(true)
            return
        }
        action(false)
        setError(true)
    }

    return (
        <div>
            <form onSubmit={handlePromotionalCode} className='box-code'>
                <input className='box-code-input' name="code" type='text' placeholder='Enter Code' />
                <input className='box-code-submit' type='submit' value="Apply" />
            </form>
            {error && <p className="text-error">Invalid code</p>}
        </div>
    )
}

export default InputCode