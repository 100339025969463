import { plansMonthly, plansYearly } from "../../constants/plans"
import CardPrice from "../CardPrice"
import Switch from "../Switch";

const PriceStep = ({handleForm, form, setForm}) => {
  
    const handleChecked = (value) => {
        setForm({...form, period: value ? "Yearly" : "Monthly"})
    }

    return (
        <>
            <div className="switch_price_container">
                <h5>Monthly</h5>
                <Switch value={form.period === "Monthly" ? false : true}  action={handleChecked} />
                <h5>Yearly</h5>
            </div>
            <div className="container-plans">
                {( form.period === "Yearly" ? plansYearly : plansMonthly).map( 
                    (item) => <CardPrice key={item.id} action={(id) => handleForm("id_plan", id)} {...item} /> 
                )}
            </div>
        </>
    )
}

export default PriceStep