import { useState } from "react";
import CardIcon from "../CardIcon";
import { formatPhoneNumberUS, validateForm } from "../utils";

const PeriodStep = ({ form, setForm, step, setStep }) => {
    const [errors, setErrors] = useState({});

    const handleCardNumberChange = (e) => {
        const { value } = e.currentTarget;

        const cleanedValue = value.replace(/\D/g, "");
        const formattedValue = cleanedValue.replace(/(\d{4})(?=\d)/g, "$1 ");
        setForm({ ...form, payment: { ...form.payment, number: formattedValue } });
    };

    const handleExpiryDateChange = (e) => {
        const input = e.currentTarget.value;
        let formattedValue = input;

        formattedValue = formattedValue.replace(/\D/g, "");

        if (formattedValue.length > 2) {
            formattedValue = `${formattedValue.slice(0, 2)}/${formattedValue.slice(
                2
            )}`;
        }
        setForm({ ...form, payment: { ...form.payment, exp: formattedValue } });
    };

    const handlePhoneNumberChange = (event) => {
        const input = event.target.value;
        const formattedNumber = formatPhoneNumberUS(input);
        setForm({
            ...form,
            account: { ...form.account, phone: formattedNumber },
        })
      };

    const handleSubmit = () => {
        console.log(form);
        const errors = validateForm(
            form.payment.name,
            form.payment.number,
            form.payment.exp,
            form.payment.cvc,
            form.account.fullname,
            form.account.email,
            form.account.phone,
            form.account.address
        );
        console.log(errors);
        if (Object.keys(errors).length !== 0) {
            setErrors(errors);
            return;
        }
        setErrors({});
        setStep(step + 1);
    };

    return (
        <div className="container-payment">
            <p className="payment-subtitle">Card Details</p>
            <div className="payment-form">
                <div className="payment-dualrow">
                    <div className="payment-row">
                        <p className="payment-row-title">Name on Card</p>
                        <input
                            className="payment-row-input"
                            placeholder="Joseph"
                            type="text"
                            value={form.payment.name}
                            onChange={(e) =>
                                setForm({
                                    ...form,
                                    payment: { ...form.payment, name: e.currentTarget.value },
                                })
                            }
                        />
                        {errors["card_name"]?.error && (
                            <p className="text-error">{errors["card_name"].message}</p>
                        )}
                    </div>
                    <div className="payment-row">
                        <p className="payment-row-title">Card Number</p>
                        <div style={{ width: "100%", position: "relative" }}>
                            <input
                                className="payment-row-input"
                                placeholder="1234 1234 1234 1234"
                                type="text"
                                value={form.payment.number}
                                onChange={handleCardNumberChange}
                                maxLength={19}
                            />
                            <div
                                style={{
                                    position: "absolute",
                                    right: 0,
                                    top: 0,
                                    padding: "0.9rem",
                                }}
                            >
                                <CardIcon cardNumber={form.payment.number} />
                            </div>
                        </div>
                        {errors["card_number"]?.error && (
                            <p className="text-error">{errors["card_number"].message}</p>
                        )}
                    </div>
                </div>
                <div className="payment-dualrow">
                    <div className="payment-row">
                        <p className="payment-row-title">Expiration Date</p>
                        <input
                            className="payment-row-input"
                            placeholder="MM / AA"
                            type="text"
                            value={form.payment.exp}
                            onChange={handleExpiryDateChange}
                            maxLength={5}
                        />
                        {errors["card_exp"]?.error && (
                            <p className="text-error">{errors["card_exp"].message}</p>
                        )}
                    </div>
                    <div className="payment-row">
                        <p className="payment-row-title">Security Code</p>
                        <input
                            className="payment-row-input"
                            placeholder="CVC"
                            type="text"
                            value={form.payment.cvc}
                            onChange={(e) =>
                                setForm({
                                    ...form,
                                    payment: { ...form.payment, cvc: e.currentTarget.value },
                                })
                            }
                            maxLength={4}
                        />
                        {errors["card_cvc"]?.error && (
                            <p className="text-error">{errors["card_cvc"].message}</p>
                        )}
                    </div>
                </div>
            </div>
            <hr></hr>
            <p className="payment-subtitle">Account Information</p>
            <div className="payment-form">
                <div className="payment-dualrow">
                    <div className="payment-row">
                        <p className="payment-row-title">Fullname</p>
                        <input
                            className="payment-row-input"
                            placeholder="Joseph"
                            type="text"
                            value={form.account.fullname}
                            onChange={(e) =>
                                setForm({
                                    ...form,
                                    account: { ...form.account, fullname: e.currentTarget.value },
                                })
                            }
                        />
                        {errors["fullname"]?.error && (
                            <p className="text-error">{errors["fullname"].message}</p>
                        )}
                    </div>
                    <div className="payment-row">
                        <p className="payment-row-title">Email</p>
                        <input
                            className="payment-row-input"
                            placeholder="example@email.com"
                            type="text"
                            value={form.account.email}
                            onChange={(e) =>
                                setForm({
                                    ...form,
                                    account: { ...form.account, email: e.currentTarget.value },
                                })
                            }
                        />
                        {errors["email"]?.error && (
                            <p className="text-error">{errors["email"].message}</p>
                        )}
                    </div>
                </div>
                <div className="payment-dualrow">
                    <div className="payment-row">
                        <p className="payment-row-title">Phone</p>
                        <input
                            className="payment-row-input"
                            placeholder="(123) 456-7890"
                            type="text"
                            value={form.account.phone}
                            onChange={handlePhoneNumberChange}
                            maxLength={14}
                        />
                        {errors["phone"]?.error && (
                            <p className="text-error">{errors["phone"].message}</p>
                        )}
                    </div>
                    <div className="payment-row">
                        <p className="payment-row-title">Address</p>
                        <input
                            className="payment-row-input"
                            placeholder="address"
                            type="text"
                            value={form.account.address}
                            onChange={(e) =>
                                setForm({
                                    ...form,
                                    account: { ...form.account, address: e.currentTarget.value },
                                })
                            }
                        />
                        {errors["address"]?.error && (
                            <p className="text-error">{errors["address"].message}</p>
                        )}
                    </div>
                </div>
            </div>
            <div className="payment-footer">
                <button onClick={() => setStep(step - 1)} className="payment-button-2">
                    Back
                </button>
                <button onClick={handleSubmit} className="payment-button">
                    Next
                </button>
            </div>
        </div>
    );
};

export default PeriodStep;
