import React from 'react';
import styled, { keyframes } from 'styled-components';

// Define la animación del loader
const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

// Estilos para el componente Loader
const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const Spinner = styled.div`
  width: 20px;
  height: 20px;
  border: 4px solid #f3f3f3;
  border-top-color: rgba(243, 117, 58, 1);
  border-radius: 50%;
  animation: ${spin} 0.8s linear infinite;
`;

// Componente Loader
const Loader = () => {
    return (
        <LoaderContainer>
            <Spinner />
        </LoaderContainer>
    );
};

export default Loader;