export const plansMonthly = [
    {
        id: 1,
        icon: "https://x-scope.net/images/xsc-icon-8.png",
        name: "Single user",
        users: "1 User",
        cant_users: 1,
        price: "$229",
        type: "Monthly",
        description: "per user / per month, billed annually ($2,748.00)",
        access: [
            // "60 Projects per month",
            // "Unlimited Satellite Measurements",
            // "Unlimited Estimates",
            // "Limited Reports",
            // "60 Photos Free",
            // "Unlimited Videos",
            // "No access to chat"
            "Unlimited Projects",
            "Unlimited Satellite Measurements",
            "Unlimited Reports",
            "Unlimited Photos",
            "Unlimited Videos",
            "Unlimited Chat"
        ]
    },
    {
        id: 2,
        icon: "https://x-scope.net/images/xsc-icon-9.png",
        name: "Business",
        users: "3 Users minimum",
        cant_users: 3,
        price: "$199",
        type: "Monthly",
        description: "per user / per month, billed annually ($2,388.00)",
        access: [
            "Unlimited Projects",
            "Unlimited Satellite Measurements",
            "Unlimited Reports",
            "Unlimited Photos",
            "Unlimited Videos",
            "Unlimited Chat"
        ]
    },
    {
        id: 3,
        icon: "https://x-scope.net/images/xsc-icon-10.png",
        name: "Enterprise",
        users: "5 User minimun",
        cant_users: 5,
        price: "$179",
        type: "Monthly",
        description: "per user / per month, billed annually ($2,148.00)",
        access: [
            "Unlimited Projects",
            "Unlimited Satellite Measurements",
            "Unlimited Reports",
            "Unlimited Photos",
            "Unlimited Videos",
            "Unlimited Chat"
        ]
    }
]

export const plansYearly = [
    {
        id: 1,
        icon: "https://x-scope.net/images/xsc-icon-8.png",
        name: "Single user",
        users: "1 User",
        cant_users: 1,
        price: "$2748",
        price_promotion: "$2473",
        type: "Yearly",
        description: "per user / year",
        access: [
            // "60 Projects per month",
            // "Unlimited Satellite Measurements",
            // "Unlimited Estimates",
            // "Limited Reports",
            // "60 Photos Free",
            // "Unlimited Videos",
            // "No access to chat"
            "Unlimited Projects",
            "Unlimited Satellite Measurements",
            "Unlimited Reports",
            "Unlimited Photos",
            "Unlimited Videos",
            "Unlimited Chat"
        ]
    },
    {
        id: 2,
        icon: "https://x-scope.net/images/xsc-icon-9.png",
        name: "Business",
        users: "3 Users minimum",
        cant_users: 3,
        price: "$2388",
        price_promotion: "$2149",
        type: "Yearly",
        description: "per user / year",
        access: [
            "Unlimited Projects",
            "Unlimited Satellite Measurements",
            "Unlimited Reports",
            "Unlimited Photos",
            "Unlimited Videos",
            "Unlimited Chat"
        ]
    },
    {
        id: 3,
        icon: "https://x-scope.net/images/xsc-icon-10.png",
        name: "Enterprise",
        users: "5 User minimun",
        cant_users: 5,
        price: "$2148",
        price_promotion: "$1933",
        type: "Yearly",
        description: "per user / year",
        access: [
            "Unlimited Projects",
            "Unlimited Satellite Measurements",
            "Unlimited Reports",
            "Unlimited Photos",
            "Unlimited Videos",
            "Unlimited Chat"
        ]
    }
]