import { useState } from 'react';
import { KEY_CAPCHA, URL_API } from "../../config";
import forge from 'node-forge';
import Loader from '../Loader';
import { plansMonthly, plansYearly } from '../../constants/plans';
import { convertStringToMoney, formatPhoneNumberUS, formatPrice } from '../utils';
import ReCAPTCHA from "react-google-recaptcha";

const getPriceByForm = (form, discount, period = "Yearly") => {
    const { id_plan, promotionalCode } = form;
    const list = period === "Monthly" ? plansMonthly : plansYearly;
    const price = promotionalCode
        ? list.find((x) => x.id === id_plan).price
        : period === "Monthly"
            ? list.find((x) => x.id === id_plan).price
            : list.find((x) => x.id === id_plan).price;

    return {
        price: convertStringToMoney(price) - (promotionalCode ? discount : 0),
        format: `$${convertStringToMoney(price) - (promotionalCode ? discount : 0)
            }/${period === "Monthly" ? "mo" : "year"}`,
    };
};



const ConfirmStep = ({ form, step, setStep }) => {
    const cant_years = parseInt(form.type || "1");
    const cant_users = plansMonthly.find((x) => x.id === form.id_plan).cant_users
    const [status, setStatus] = useState(0);
    const [loading, setloading] = useState(false);
    const [tokenCaptcha, setTokenCaptcha] = useState(null);

    const onChangeCaptcha = (value) => {
        setTokenCaptcha(value);
        //if (value != null && value != undefined) setMsgErrorCaptcha("");
        //else setMsgErrorCaptcha(defautlErrorCaptcha);
    };

    const handleSubmit = async () => {
        try {
            if(!tokenCaptcha) return
            //validate
            setloading(true)
            const payload = {
                name: form.payment.name,
                number: form.payment.number,
                exp: form.payment.exp,
                cvc: form.payment.cvc
            }
            const response = await fetch(`${URL_API}/api/landing-xscope/getKey`);
            const resp = await response.json();
            const publicKeyPem = resp.publicKey;


            const publicKey = forge.pki.publicKeyFromPem(publicKeyPem);

            const encrypted = forge.util.encode64(publicKey.encrypt(JSON.stringify(payload)));

            const response2 = await fetch(`${URL_API}/api/landing-xscope/buy`, {
                headers: {
                    'Content-Type': 'application/json'
                },
                method: "POST",
                body: JSON.stringify({
                    account: form.account,
                    payload: encrypted,
                    id_plan: form.id_plan,
                    period: form.period,
                    type: form.type,
                    promotionalCode: form.promotionalCode,
                    check_terms: form.check_terms,
                    captcha: tokenCaptcha,
                })
            });

            const resp2 = await response2.json();

            if (resp2.status !== "success") throw new Error("error")

            setStatus(1)
            setloading(false)
        } catch (error) {
            console.log(error)
            setloading(false)
            if (error?.message === "form-error") {
                setStatus(0)
                return
            }
            setStatus(2)
        }
    }

    return (
        <div className="container-payment">
            <div className='container-summary'>
                <div className='summary-row'>
                    <div className='summary-col'>
                        <p>Fullname: </p>
                    </div>
                    <div className='summary-col'>
                        <p>{form.account.fullname}</p>
                    </div>
                </div>
                <div className='summary-row'>
                    <div className='summary-col'>
                        <p>Email: </p>
                    </div>
                    <div className='summary-col'>
                        <p>{form.account.email}</p>
                    </div>
                </div>
                <div className='summary-row'>
                    <div className='summary-col'>
                        <p>Phone: </p>
                    </div>
                    <div className='summary-col'>
                        <p>{form.account.phone}</p>
                    </div>
                </div>
                <hr></hr>
                <div className='summary-row'>
                    <div className='summary-col'>
                        <p>Card Number: </p>
                    </div>
                    <div className='summary-col'>
                        <p>**** **** **** {form.payment.number.slice(15)}</p>
                    </div>
                </div>
                <div className='summary-row'>
                    <div className='summary-col'>
                        <p>Plan: </p>
                    </div>
                    <div className='summary-col'>
                        <p>{form.id_plan === 3 ? "Enterprise" : form.id_plan === 2 ? "Business" : "Single user"}</p>
                    </div>
                </div>
                <div className='summary-row'>
                    <div className='summary-col'>
                        <p>Period: </p>
                    </div>
                    <div className='summary-col'>
                        <p>{form.type} Years</p>
                    </div>
                </div>
                {form.period === "Monthly" && <div className='summary-row'>
                    <div className='summary-col'>
                        <p>Term: </p>
                    </div>
                    <div className='summary-col'>
                        <p>{parseInt(form.type || "1") * 12} months</p>
                    </div>
                </div>}
                <div className='summary-row'>
                    <div className='summary-col'>
                        <p>Total Users: </p>
                    </div>
                    <div className='summary-col'>
                        <p>{cant_users} users</p>
                    </div>
                </div>
                {/*<div className='summary-row'>
                    <div className='summary-col'>
                        <p>Price per user in {cant_years} years: </p>
                    </div>
                    <div className='summary-col'>
                        <p>{formatPrice((convertStringToMoney(plansYearly.find(x => x.id === form.id_plan).price) - (form.promotionalCode ? form.type === "3" ? 500 : 250 : 0)) * parseInt(form.type || "1"))}</p>
                    </div>
    </div>*/}
                <div className='summary-row'>
                    <div className='summary-col'>
                        <p>Total {form.id_plan === 3 ? "Enterprise" : form.id_plan === 2 ? "Business" : "Single"} package: </p>
                    </div>
                    <div className='summary-col'>
                        <p>
                            {
                                formatPrice(
                                    (
                                        convertStringToMoney(plansYearly.find(x => x.id === form.id_plan).price) 
                                        - (form.promotionalCode ? form.type === "3" ? 333.3 : 250 : 0)
                                    ) 
                                    * cant_users 
                                    * parseInt(form.type || "1")
                                )
                            }
                        </p>
                    </div>
                </div>
                {(form.promotionalCode && form.period === "Yearly" && form.type === "3") && (<>
                    <div className='summary-row'>
                                <div className='summary-col'>
                                    <p>1st year: </p>
                                </div>
                                <div className='summary-col'>
                                    <p>
                                        {form.promotionalCode && (
                                            <span className="tachado">{formatPrice(getPriceByForm(form, 0).price * cant_users)}</span>
                                        )}
                                        &nbsp;
                                        {formatPrice(getPriceByForm(form, 250).price * cant_users)}
                                    </p>
                                </div>
                            </div>
                    <div className='summary-row'>
                                <div className='summary-col'>
                                    <p>2nd year: </p>
                                </div>
                                <div className='summary-col'>
                                    <p>
                                        {form.promotionalCode && (
                                            <span className="tachado">{formatPrice(getPriceByForm(form, 0).price * cant_users)}</span>
                                        )}
                                        &nbsp;
                                        {formatPrice(getPriceByForm(form, 250).price * cant_users)}
                                    </p>
                                </div>
                            </div>
                            <div className='summary-row'>
                                <div className='summary-col'>
                                    <p>3rd year: </p>
                                </div>
                                <div className='summary-col'>
                                    <p>
                                        {form.promotionalCode && (
                                            <span className="tachado">{formatPrice(getPriceByForm(form, 0).price * cant_users)}</span>
                                        )}
                                        &nbsp;
                                        {formatPrice(getPriceByForm(form, 500).price * cant_users)}
                                    </p>
                                </div>
                            </div>
                </>)}
                {(!form.promotionalCode && form.period === "Yearly" && form.type === "3") && (<>
                    <div className='summary-row'>
                            <div className='summary-col'>
                                <p>1st year: </p>
                            </div>
                            <div className='summary-col'>
                                <p>
                                    {form.promotionalCode && (
                                        <span className="tachado">{formatPrice(getPriceByForm(form, 0).price * cant_users)}</span>
                                    )}
                                    &nbsp;
                                    {formatPrice(getPriceByForm(form, form.type === "3" ? 333 : 250).price * cant_users)}
                                </p>
                            </div>
                        </div>
                        <div className='summary-row'>
                            <div className='summary-col'>
                                <p>2nd year: </p>
                            </div>
                            <div className='summary-col'>
                                <p>
                                    {form.promotionalCode && (
                                        <span className="tachado">{formatPrice(getPriceByForm(form, 0).price * cant_users)}</span>
                                    )}
                                    &nbsp;
                                    {formatPrice(getPriceByForm(form, form.type === "3" ? 333 : 250).price * cant_users)}
                                </p>
                            </div>
                        </div>
                        <div className='summary-row'>
                            <div className='summary-col'>
                                <p>3rd year: </p>
                            </div>
                            <div className='summary-col'>
                                <p>
                                    {form.promotionalCode && (
                                        <span className="tachado">{formatPrice(getPriceByForm(form, 0).price * cant_users)}</span>
                                    )}
                                    &nbsp;
                                    {formatPrice(getPriceByForm(form, form.type === "3" ? 333 : 250).price * cant_users)}
                                </p>
                            </div>
                        </div>
                </>)}
                {(form.period === "Yearly" && form.type === "2") && (<>
                    <div className='summary-row'>
                            <div className='summary-col'>
                                <p>1st year: </p>
                            </div>
                            <div className='summary-col'>
                                <p>
                                    {form.promotionalCode && (
                                        <span className="tachado">{formatPrice(getPriceByForm(form, 0).price * cant_users)}</span>
                                    )}
                                    &nbsp;
                                    {formatPrice(getPriceByForm(form, form.type === "3" ? 333 : 250).price * cant_users)}
                                </p>
                            </div>
                        </div>
                        <div className='summary-row'>
                            <div className='summary-col'>
                                <p>2nd year: </p>
                            </div>
                            <div className='summary-col'>
                                <p>
                                    {form.promotionalCode && (
                                        <span className="tachado">{formatPrice(getPriceByForm(form, 0).price * cant_users)}</span>
                                    )}
                                    &nbsp;
                                    {formatPrice(getPriceByForm(form, form.type === "3" ? 333 : 250).price * cant_users)}
                                </p>
                            </div>
                        </div>                        
                </>)}
                {(form.period === "Yearly" && form.type === "1") && (<>
                    <div className='summary-row'>
                            <div className='summary-col'>
                                <p>1st year: </p>
                            </div>
                            <div className='summary-col'>
                                <p>
                                    {form.promotionalCode && (
                                        <span className="tachado">{formatPrice(getPriceByForm(form, 0).price * cant_users)}</span>
                                    )}
                                    &nbsp;
                                    {formatPrice(getPriceByForm(form, form.type === "3" ? 333 : 250).price * cant_users)}
                                </p>
                            </div>
                        </div>
                </>)}
                {/* {
                    (form.promotionalCode && form.period === "Yearly" && form.type === "3")
                        ? <>
                            <div className='summary-row'>
                                <div className='summary-col'>
                                    <p>First 2 yearly installments: </p>
                                </div>
                                <div className='summary-col'>
                                    <p>
                                        {form.promotionalCode && (
                                            <span className="tachado">{formatPrice(getPriceByForm(form, 0).price * cant_users)}</span>
                                        )}
                                        &nbsp;
                                        {formatPrice(getPriceByForm(form, 250).price * cant_users)}
                                    </p>
                                </div>
                            </div>
                            <div className='summary-row'>
                                <div className='summary-col'>
                                    <p>Last yearly installment: </p>
                                </div>
                                <div className='summary-col'>
                                    <p>
                                        {form.promotionalCode && (
                                            <span className="tachado">{formatPrice(getPriceByForm(form, 0).price * cant_users)}</span>
                                        )}
                                        &nbsp;
                                        {formatPrice(getPriceByForm(form, 500).price * cant_users)}
                                    </p>
                                </div>
                            </div>
                        </>
                        : (form.period === "Yearly") && <div className='summary-row'>
                            <div className='summary-col'>
                                <p>Yearly Installment: </p>
                            </div>
                            <div className='summary-col'>
                                <p>
                                    {form.promotionalCode && (
                                        <span className="tachado">{formatPrice(getPriceByForm(form, 0).price * cant_users)}</span>
                                    )}
                                    &nbsp;
                                    {formatPrice(getPriceByForm(form, form.type === "3" ? 333 : 250).price * cant_users)}
                                </p>
                            </div>
                        </div>
                } */}

                {(form.promotionalCode && form.period === "Monthly" && form.type === "3")&&(<>
                    <div className='summary-row'>
                                <div className='summary-col'>
                                    <p>1st year monthly: </p>
                                </div>
                                <div className='summary-col'>
                                    <p>
                                        {form.promotionalCode && (
                                            <span className="tachado">{formatPrice(getPriceByForm(form, 0, "Monthly").price * cant_users)}</span>
                                        )}
                                        &nbsp;
                                        {formatPrice(getPriceByForm(form, 21, "Monthly").price * cant_users)}
                                    </p>
                                </div>
                            </div>
                    <div className='summary-row'>
                                <div className='summary-col'>
                                    <p>2nd year monthly: </p>
                                </div>
                                <div className='summary-col'>
                                    <p>
                                        {form.promotionalCode && (
                                            <span className="tachado">{formatPrice(getPriceByForm(form, 0, "Monthly").price * cant_users)}</span>
                                        )}
                                        &nbsp;
                                        {formatPrice(getPriceByForm(form, 21, "Monthly").price * cant_users)}
                                    </p>
                                </div>
                            </div>
                            <div className='summary-row'>
                                <div className='summary-col'>
                                    <p>3rd year monthly: </p>
                                </div>
                                <div className='summary-col'>
                                    <p>
                                        {form.promotionalCode && (
                                            <span className="tachado">{formatPrice(getPriceByForm(form, 0, "Monthly").price * cant_users)}</span>
                                        )}
                                        &nbsp;
                                        {formatPrice(getPriceByForm(form, 42, "Monthly").price * cant_users)}
                                    </p>
                                </div>
                            </div>
                </>)}

                {(!form.promotionalCode && form.period === "Monthly" && form.type === "3")&&(<>
                    <div className='summary-row'>
                            <div className='summary-col'>
                                <p>1st year monthly: </p>
                            </div>
                            <div className='summary-col'>
                                <p>
                                    {form.promotionalCode && (
                                        <span className="tachado">{formatPrice(getPriceByForm(form, 0, "Monthly").price * cant_users)}</span>
                                    )}
                                    &nbsp;
                                    {formatPrice(getPriceByForm(form, form.type === "3" ? 28 : 21, "Monthly").price * cant_users)}
                                </p>
                            </div>
                        </div>
                        <div className='summary-row'>
                            <div className='summary-col'>
                                <p>2nd year monthly: </p>
                            </div>
                            <div className='summary-col'>
                                <p>
                                    {form.promotionalCode && (
                                        <span className="tachado">{formatPrice(getPriceByForm(form, 0, "Monthly").price * cant_users)}</span>
                                    )}
                                    &nbsp;
                                    {formatPrice(getPriceByForm(form, form.type === "3" ? 28 : 21, "Monthly").price * cant_users)}
                                </p>
                            </div>
                        </div>
                        <div className='summary-row'>
                            <div className='summary-col'>
                                <p>3rd year monthly: </p>
                            </div>
                            <div className='summary-col'>
                                <p>
                                    {form.promotionalCode && (
                                        <span className="tachado">{formatPrice(getPriceByForm(form, 0, "Monthly").price * cant_users)}</span>
                                    )}
                                    &nbsp;
                                    {formatPrice(getPriceByForm(form, form.type === "3" ? 28 : 21, "Monthly").price * cant_users)}
                                </p>
                            </div>
                        </div>
                </>)}

                {(form.period === "Monthly" && form.type === "2")&&(<>
                    <div className='summary-row'>
                            <div className='summary-col'>
                                <p>1st year monthly: </p>
                            </div>
                            <div className='summary-col'>
                                <p>
                                    {form.promotionalCode && (
                                        <span className="tachado">{formatPrice(getPriceByForm(form, 0, "Monthly").price * cant_users)}</span>
                                    )}
                                    &nbsp;
                                    {formatPrice(getPriceByForm(form, form.type === "3" ? 28 : 21, "Monthly").price * cant_users)}
                                </p>
                            </div>
                        </div>
                        <div className='summary-row'>
                            <div className='summary-col'>
                                <p>2nd year monthly: </p>
                            </div>
                            <div className='summary-col'>
                                <p>
                                    {form.promotionalCode && (
                                        <span className="tachado">{formatPrice(getPriceByForm(form, 0, "Monthly").price * cant_users)}</span>
                                    )}
                                    &nbsp;
                                    {formatPrice(getPriceByForm(form, form.type === "3" ? 28 : 21, "Monthly").price * cant_users)}
                                </p>
                            </div>
                        </div>
                </>)}

                {(form.period === "Monthly" && form.type === "1")&&(<>
                    <div className='summary-row'>
                            <div className='summary-col'>
                                <p>1st year monthly: </p>
                            </div>
                            <div className='summary-col'>
                                <p>
                                    {form.promotionalCode && (
                                        <span className="tachado">{formatPrice(getPriceByForm(form, 0, "Monthly").price * cant_users)}</span>
                                    )}
                                    &nbsp;
                                    {formatPrice(getPriceByForm(form, form.type === "3" ? 28 : 21, "Monthly").price * cant_users)}
                                </p>
                            </div>
                        </div>
                </>)}
                {/* {
                    (form.promotionalCode && form.period === "Monthly" && form.type === "3")
                        ? <>
                            <div className='summary-row'>
                                <div className='summary-col'>
                                    <p>First 24 monthly installments: </p>
                                </div>
                                <div className='summary-col'>
                                    <p>
                                        {form.promotionalCode && (
                                            <span className="tachado">{formatPrice(getPriceByForm(form, 0, "Monthly").price * cant_users)}</span>
                                        )}
                                        &nbsp;
                                        {formatPrice(getPriceByForm(form, 21, "Monthly").price * cant_users)}
                                    </p>
                                </div>
                            </div>
                            <div className='summary-row'>
                                <div className='summary-col'>
                                    <p>Last 12 monthly installments: </p>
                                </div>
                                <div className='summary-col'>
                                    <p>
                                        {form.promotionalCode && (
                                            <span className="tachado">{formatPrice(getPriceByForm(form, 0, "Monthly").price * cant_users)}</span>
                                        )}
                                        &nbsp;
                                        {formatPrice(getPriceByForm(form, 42, "Monthly").price * cant_users)}
                                    </p>
                                </div>
                            </div>
                        </>
                        : (form.period === "Monthly") && <div className='summary-row'>
                            <div className='summary-col'>
                                <p>Monthly Installment: </p>
                            </div>
                            <div className='summary-col'>
                                <p>
                                    {form.promotionalCode && (
                                        <span className="tachado">{formatPrice(getPriceByForm(form, 0, "Monthly").price * cant_users)}</span>
                                    )}
                                    &nbsp;
                                    {formatPrice(getPriceByForm(form, form.type === "3" ? 28 : 21, "Monthly").price * cant_users)}
                                </p>
                            </div>
                        </div>
                } */}
                {form.promotionalCode && <div className='summary-row'>
                    <div className='summary-col'>
                        <p>Promotion Code: </p>
                    </div>
                    <div className='summary-col'>
                        <p>FRSA23</p>
                    </div>
                </div>}
                <hr></hr>
                <div className='summary-row'>
                    <div className='summary-col'>
                        <p>First payment: </p>
                    </div>
                    <div className='summary-col'>
                        <p>
                            {formatPrice(getPriceByForm(form, form.period === "Monthly" ? 21 : 250, form.period).price * cant_users)}
                        </p>
                    </div>
                </div>
                <hr></hr>
            </div>
            {status === 1 && <div className='spam-successfull' style={{ maxWidth: 400, margin: "0 auto", textAlign: "center" }}>
                <p>Successful purchase, An email was sent to you with your account details.</p>
            </div>}
            {status === 2 && <div className='spam-error' style={{ maxWidth: 400, margin: "0 auto", textAlign: "center" }}>
                <p>We had problems processing your purchase, please try again later.</p>
            </div>}
            {
                status !== 1
                    ? <div className='container-summarybtn' style={{flexDirection: "column", gap: "1rem"}}>
                        <ReCAPTCHA hl={"en"} sitekey={KEY_CAPCHA} onChange={onChangeCaptcha} />
                        <button className={`btn-order ${!tokenCaptcha && "disabled"}`} onClick={handleSubmit}>{loading ? <Loader /> : "CONFIRM PAYMENT"}</button>
                    </div>
                    : <br></br>
            }
            {status !== 1 && <div className="payment-footer" style={{ maxWidth: 400, margin: "0 auto" }}>
                <button onClick={() => setStep(step - 1)} className="payment-button-2">Back</button>
                <div></div>
            </div>}
        </div>
    )
}

export default ConfirmStep