const Switch = ({value, action}) => {

    const handleChecked = () => action(!value)
    

    return(
        <div className="clase1" onClick={handleChecked}>
            <div className={`react-switch-bg clase2${value ? "-2":""}`}></div>
            <div className={`react-switch-handle clase3${value ? "-2":""}`}></div>
            <input 
                className="clase4" 
                checked={value} 
                type="checkbox" 
                onChange={handleChecked}
                role="switch" aria-checked="false"
            />
        </div>
    )
}

export default Switch