import { useState, useEffect  } from 'react';
import './App.css';
import Logo from "./assets/logo4x.png"
import PriceStep from './components/steps/PriceStep';
import PaymentStep from './components/steps/PaymentStep';
import TermsConditions from './components/steps/TermsConditions';
import ConfirmStep from './components/steps/ConfirmStep';
import PeriodStep from './components/steps/PeriodStep';

function App() {
  const [step, setStep] = useState(1);
  const [form, setForm] = useState({
    id_plan: null, //id
    period: "Monthly", // Yearly / Monthly / 3year
    type: "1",
    promotionalCode: false,
    payment: {
      name: null,
      number: null,
      exp: null,
      cvc: null,
    },
    check_terms: false,
    account: {
      fullname: null,
      email: null,
      phone: null,
      address: null
    }
  });

  
  useEffect(() => {
    if (step) {
      window.scrollTo(0, 0);
    }
  }, [step]);

  const handleForm = (key, value) => {
    setForm({...form, [key]: value})
    setStep(step + 1)
  }

  return (
    <div className="App">
      <header className='header'>
        <img src={Logo} width={240} height={80}/>
      </header>
      <menu className='subheader'>
        {/*<div className='container-steps'>
          <div className={`step ${step >= 1 && "active"}`}></div>
          <div className={`step ${step >= 2 && "active"}`}></div>
          <div className={`step ${step >= 3 && "active"}`}></div>
          <div className={`step ${step >= 4 && "active"}`}></div>
  </div>*/}
        {step === 1 && <p className='subheader-title'>Select your plan</p>}
        {step === 2 && <p className='subheader-title'>Select your payment option</p>}
        {step === 3 && <p className='subheader-title'>Payment method</p>}
        {step === 4 && <p className='subheader-title'>Terms and conditions</p>}
        {step === 5 && <p className='subheader-title'>Confirmation</p>}
      </menu>
      <div className='body-container'>
        {step === 1 &&  <PriceStep handleForm={handleForm} form={form} setForm={setForm}/>}
        {step === 2 &&  <PeriodStep form={form} setForm={setForm} step={step} setStep={setStep}/>}
        {step === 3 &&  <PaymentStep form={form} setForm={setForm} step={step} setStep={setStep}/>}
        {step === 4 &&  <TermsConditions form={form} setForm={setForm} step={step} setStep={setStep}/>}
        {step === 5 &&  <ConfirmStep form={form} step={step} setStep={setStep}/>}
      </div>
    </div>
  );
}

export default App;
