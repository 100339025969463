import React from 'react';
import { BsCreditCard2FrontFill } from 'react-icons/bs';
import visa from '../assets/Visa.svg';
import mastercad from '../assets/mastercad.svg';

const CardIcon = ({ cardNumber }) => {
  const getCardType = (cardNumber) => {
    const visaRegex = /^4/;
    const mastercardRegex = /^5[1-5]/;
    const amexRegex = /^3[47]/;

    if (visaRegex.test(cardNumber)) {
      return 'visa';
    } else if (mastercardRegex.test(cardNumber)) {
      return 'mastercard';
    } else if (amexRegex.test(cardNumber)) {
      return 'amex';
    } else {
      return 'default';
    }
  };

  const cardType = getCardType(cardNumber);

  const getCardIcon = () => {
    switch (cardType) {
      case 'visa':
        return <img src={visa} width={20} height={20} />;
      case 'mastercard':
        return <img src={mastercad} width={20} height={20} />;
      default:
        return <BsCreditCard2FrontFill size={20} />;
    }
  };

  return getCardIcon();
};

export default CardIcon;