export function validateForm(name, number, expiration, cvv, fullname, email, phone, address) {
    const errors = {}
    try {
        const regexName = /^[a-zA-Z\s]+$/;
        if (!name || !regexName.test(name)) {
            errors["card_name"] = { error: true, message: "Invalid name" };
        }

        if (!number || !validateCardNumber(number)) {
            errors["card_number"] = { error: true, message: "Invalid card number" };
        }

        if (!expiration || !validateExpiryDate(expiration)) {
            errors["card_exp"] = { error: true, message: "Invalid expiration date" };
        }

        const regexCVV = /^\d{3,4}$/;
        if (!cvv || !regexCVV.test(cvv)) {
            errors["card_cvc"] = { error: true, message: "Invalid cvc" };
        }

        const regexFullName = /^[a-zA-Z\s]+$/;
        if (!fullname || !regexFullName.test(fullname)) {
            errors["fullname"] = { error: true, message: "Invalid fullname" };
        }

        const regexEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!email || !regexEmail.test(email)) {
            errors["email"] = { error: true, message: "Invalid email" };
        }

        const regexPhone = /^\(\d{3}\) \d{3}-\d{4}$/;
        if (!phone || !regexPhone.test(phone)) {
            errors["phone"] = { error: true, message: "Invalid phone" };
        }

        if (!address || address.length < 5 || address.length > 100) {
            errors["address"] = { error: true, message: "Invalid address" };
        }

        return errors;
    } catch (error) {
        console.log(error)
        errors["default"] = { error: true, message: "Error" };
        return errors;
    }
}




export function validateEmail(email) {
    try {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if (regex.test(email)) {
            return true;
        } else {
            return false;
        }
    } catch (error) {
        console.log(error)
        return false
    }
}

export const validateCardNumber = (cardNumber) => {
    try {
        const cleanedNumber = cardNumber.replace(/\s+/g, '').replace(/\D/g, '');

        if (cleanedNumber.length < 13 || cleanedNumber.length > 16) {
            return false;
        }

        let sum = 0;
        let double = false;

        for (let i = cleanedNumber.length - 1; i >= 0; i--) {
            let digit = parseInt(cleanedNumber.charAt(i), 10);

            if (double) {
                digit *= 2;

                if (digit > 9) {
                    digit -= 9;
                }
            }

            sum += digit;
            double = !double;
        }

        return sum % 10 === 0;
    } catch (error) {
        console.log(error)
        return false
    }
};

export const validateExpiryDate = (expiryDate) => {
    try {
        const pattern = /^(0[1-9]|1[0-2])\/(\d{2})$/;
        const match = expiryDate.match(pattern);

        if (!match) {
            throw new Error("No match")
        }

        const month = parseInt(match[1], 10);
        const year = parseInt(match[2], 10);
        const currentYear = new Date().getFullYear() % 100;

        if (year < currentYear || (year === currentYear && month < new Date().getMonth() + 1)) {
            throw new Error("Year is not valid")
        }

        return true;
    } catch (error) {
        console.log(error)
        return false
    }
};

export const validateCVC = (cvc) => {
    try {
        const pattern = /^\d{3,4}$/;
        return pattern.test(cvc);
    } catch (error) {
        console.log(error)
        return false
    }
};


export const convertStringToMoney = (str) => {
    try {
        return parseFloat(str.replace("$", ""));
    } catch (error) {
        console.log(error)
        return 0
    }
};

export function formatPrice(price) {
    try {
        const formattedPrice = parseInt(price);

        const formattedPriceString = formattedPrice.toLocaleString('en-US', { style: 'currency', currency: 'USD' });

        return formattedPriceString;
    } catch (error) {
        console.log(error)
        return (0).toLocaleString('en-US', { style: 'currency', currency: 'USD' })
    }
}

export function formatPhoneNumberUS(numberString) {
    const numericOnly = numberString.replace(/\D/g, '');

    const formattedNumber = numericOnly.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');

    return formattedNumber;
}