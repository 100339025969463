const TermsConditions = ({form, setForm, step, setStep}) => {

    const handleChecked = () => {
        setForm({...form, check_terms: !form.check_terms})
    }

    const handleNext = () => {
        if(form.check_terms) setStep(step + 1)
    }

    return (
        <div className="container-terms">
            <iframe className="terms" src="https://app.x-scope.net/static/media/Terms.c1e7f6ca.htm"></iframe>
            <div className="terms-checkbox">
                <input checked={form.check_terms} type="checkbox" onChange={handleChecked} />
                <p style={{cursor: "pointer"}} onClick={handleChecked}>I have read and accept the terms and conditions of use</p>
            </div>
            <div className="payment-footer">
                <button onClick={() => setStep(step - 1)} className="payment-button-2">Back</button>
                <button onClick={handleNext} className={`payment-button ${!form.check_terms && "disabled"}`}>Next</button>
            </div>
        </div>
    )
}

export default TermsConditions